import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

const data = [
  { label: '0qw57l86', value: 123123 },
  { label: 'plw5gkqg', value: 123123 },
  { label: 'wqlfacc0', value: 123123 },
  { label: 'uqmku0j0', value: 123123 },
  { label: 'nscddnlc', value: 123123 },
  { label: 'wqt2t760', value: 123123 },
  { label: 'vqg5t3q6', value: 123123 },
  { label: '0qqa2vya', value: 123123 },
  { label: '5qzznsm6', value: 123123 },
  { label: 'dstllky8', value: 123123 },
  { label: 'ys26zj7d', value: 123123 },
  { label: 'yq0awdhh', value: 123123 },
  { label: '4ap6p29l', value: 123123 },
  { label: 'jsxt8w6t', value: 123123 },
  { label: 'as0l7fd4', value: 123123 },
  { label: 'eq57tvvv', value: 123123 },
  { label: '6m2dyvma', value: 123123 },
  { label: 'wqh2znwr', value: 123123 },
  { label: 'cs83auxl', value: 123123 },
  { label: 'qqhuyea2', value: 123123 },
  { label: 'lqdqxqrf', value: 123123 },
  { label: 'qsxel8ap', value: 123123 },
  { label: 'mqtegutx', value: 123123 },
  { label: 'xq4qvp35', value: 123123 },
  { label: 'ws4l8t43', value: 123123 },
];

const PointsBreakdown = () => {
  return (
    <Box color="orange.500" fontFamily="monospace" fontSize="14px" p={4}>
      {/* Header */}
      <Flex justify="center" mb={4}>
        <Text> Welcome to our matrix help decode and re build I know you got the vision. (Points loading...)  
          26 Subscriptions | 806 RUNE Transactions | 36 Runes Distributed | 75,999 Mints | 3,965,405 ORANGE•PILLS SACRIFICED
        </Text>
      </Flex>

      {/* Data List */}
      <Box>
        {data.map((item, index) => (
          <Flex
            key={index}
            justify="space-between"
            align="center"
            whiteSpace="nowrap"
          >
            {/* Label */}
            <Text>{index + 1}............{item.label}</Text>

            {/* Dotted Line */}
            <Box flex="1" mx={2} borderBottom="1px dotted orange" />

            {/* Value */}
            <Text>{item.value.toLocaleString()}</Text>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default PointsBreakdown;
