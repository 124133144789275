import React from 'react';
import {
  Box,
  Text,
  VStack,
  Input,
} from '@chakra-ui/react';

const PlayerAccolades = () => {
  return (
    <VStack spacing={8} align="stretch">
      {/* Title */}
      <Text
        fontSize="24px"
        fontWeight="bold"
        color="#ff9c00"
        textAlign="center"
      >
        ORANGE•PILLS SEASON #1
      </Text>

      {/* Search Section */}
      <VStack spacing={4}>
        <Text
          color="#ff9c00"
          fontSize="lg"
          textAlign="center"
        >
          Search your wallet address to see your profile
        </Text>
        <Box width="100%" maxW="800px" mx="auto">
          <Input
            placeholder="Brc..."
            bg="#663300"
            border="none"
            color="#ff9c00"
            height="50px"
            fontSize="lg"
            textAlign="center"
            _placeholder={{ color: '#ff9c00', opacity: 0.7 }}
            _hover={{ bg: '#804000' }}
            _focus={{ 
              bg: '#804000',
              boxShadow: 'none',
              borderColor: 'transparent'
            }}
          />
        </Box>
      </VStack>

      {/* Placeholder content while functionality is being built */}
      <Box mt={10} textAlign="center">
        <Text color="#ff9c00" fontSize="sm">
          Profile search functionality will be available soon.
        </Text>
      </Box>
    </VStack>
  );
};

export default PlayerAccolades;