import React, { useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Input,
} from '@chakra-ui/react';
import SubNav from '../components/SubNav'

const Screen6 = () => {
  const [activeItem, setActiveItem] = useState('POINTS BREAKDOWN');
  const [searchValue, setSearchValue] = useState('Brc...');

  return (
    <VStack spacing={6} align="stretch">
      {/* <SubNav activeItem={activeItem} setActiveItem={setActiveItem} /> */}
      
      {/* Search Section */}
      <VStack spacing={4} px={4} pt={8}>
        <Text
          color="#ff9c00"
          fontSize="14px"
          textAlign="center"
        >
          Search your wallet address to see your profile
        </Text>

        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Enter wallet address..."
          bg="#663300"
          border="none"
          color="#ff9c00"
          _placeholder={{ color: "#ff9c00", opacity: 0.7 }}
          _hover={{ bg: "#804000" }}
          _focus={{ 
            bg: "#804000",
            boxShadow: "none",
            borderColor: "transparent"
          }}
          height="40px"
          fontSize="14px"
          textAlign="center"
          maxW="400px"
          width="100%"
        />
      </VStack>
    </VStack>
  );
};

export default Screen6;