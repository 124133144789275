import React from 'react';
import { AgentChat } from '@embedapi/react';
import {
  Box,
  Text,
  Stack,
} from '@chakra-ui/react';

const Screen2 = () => {
  return (
    <Stack spacing={6}>
      {/* Wake up text */}
      <Text
        color="#ff9c00"
        fontSize="md"
      >
        Wake up DEGEN...
      </Text>

      {/* Dark gray section */}
      <Box
        bg="#333333"
        w="100%"
        // h="200px"
        position="relative"
      >
        {/* Blinking cursor */}
        <Text
          color="#ff9c00"
          position="absolute"
          top={4}
          left={4}
          animation="blink 1s step-end infinite"
        >
          |
        </Text>
        <AgentChat
          agentId="bZ7UhtE1C4UdyugVZcyn"
          theme="dark"
          placeholder="Dare to ask me anything?"
          maxHeight="400px"
          onError={(error) => console.error('Chat error:', error)}
        />
      </Box>

      <style jsx global>{`
        @keyframes blink {
          50% {
            opacity: 0;
          }
        }
      `}</style>
    </Stack>
  );
};

export default Screen2;