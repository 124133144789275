import React from 'react';
import {
  Box,
  Text,
  Grid,
  Image,
} from '@chakra-ui/react';

const StatusCard = ({ title, image, index }) => {
  // Array of different colors using standard color names or hex values
  const colors = ['#FF0000', '#0000FF', '#00FF00', '#FFA500', '#800080', '#FFD700'];
  // Or using color names:
  // const colors = ['red', 'blue', 'green', 'orange', 'purple', 'yellow'];
  
  const circleColor = colors[index % colors.length];

  console.log(circleColor, index, index % colors.length)

  return (
    <Box bg="black" borderRadius="sm" overflow="hidden">
      <Image 
        src={`/images/${image}`}
        alt={title}
        width="100%"
        height="auto"
        objectFit="cover"
      />
      <Box py={2}>
        <Text 
          color="white" 
          textAlign="center"
          fontSize="13px"
          fontFamily="monospace"
          fontWeight="bold"
          mb={1}
        >
          {title}
        </Text>
        <Text 
          textAlign="center"
          color="white"
          fontSize="13px"
          fontFamily="monospace"
        >
          STATUS <Box 
            as="span" 
            display="inline-block" 
            w="8px" 
            h="8px" 
            borderRadius="full" 
            bg={circleColor} 
            ml={1} 
          />
        </Text>
      </Box>
    </Box>
  );
};

const Screen3 = () => {
  const cards = [
    {
      title: "WORLD•FEDERAL•RESERVE•SYSTEM",
      image: "wfrs.png",
    }, 
    {
      title: "CYBERSECURITY•VC",
      image: "cybersecuirty_vc.png",
    },
    {
      title: "TOP•SECRET•INFORMATION",
      image: "top_secret_information.png",
    },
    {
      title: "STAR•FLEET•COMMAND",
      image: "starfleet_command.png",
    },
      {
      title: "ALPHA•SQUADRON•LEADER",
      image: "alpha_squadron_leader.png",
    },
    {
      title: "DADDY'S•WAR•ROOM",
      image: "daddys_war_room.png",
    },
    {
      title: "SPACE•MARINES",
      image: "space_marines.png",
    },
    {
      title: "ALL•SYSTEMS•ONLINE",
      image: "all_systems.png",
    },
    {
      title: "SACRIFICE•VENTURE•CAPITAL",
      image: "sacrifice_venture_capital.png",
    },
    {
      title: "ALL•PAST•LIVES•MATTER",
      image: "all_past_lives_matter.png",
    },
    {
      title: "ARCTURIAN•ENVOY",
      image: "arcturian_enovy.png",
    },
    {
      title: "BITCOIN•CAULDRON",
      image: "bitcoin_cauldron.png",
    },
    {
      title: "BLOOD•DIAMONDS",
      image: "blood_diamonds.png",
    },
    {
      title: "MEME•LORD•VENTURE•CAPITAL",
      image: "memelord_vc.png",
    },
    {
      title: "PROOF•OF•CONCEPT",
      image: "proof_of_concept.png",
    },
    {
      title: "LET•ME•COOK•BRUH",
      image: "let_me_cook_bruh.png",
    },
    {
      title: "SEX•ROBOTS•FOR•SALE",
      image: "sex_robots_for_sale.png",
    },
    {
      title: "FROM•THE•FUTURE",
      image: "from_the_future.png",
    },
    {
      title: "THIS•IS•MY•RETIREMENT•PLAN",
      image: "this_is_my_retirement_plan.png",
    },
    {
      title: "PASSPORT•BROS",
      image: "passport_bros.png",
    },
    {    
      title: "MAGICAL•ITEMS",
      image: "magical_items.png",
    },
    {
      title: "MAGIK•MUSHROOMS",
      image: "magik_mushrooms.png",
    },
    {
      title: "SCHIZOID•VENTURE•CAPITAL",
      image: "schiziod_vc.jpg",
    },
    {
      title: "DONALD TRUMP JD VANCE",
      image: "donald_trump_jd_vance.png",
    },
    {
      title: "OUT•OF•WATER•WEN",
      image: "out_of_water_wen.png",
    },
    {
      title: "RUNE•FACTORY",
      image: "rune_factory.png",
    },
    {
      title: "PUMP•AND•DUMP",
      image: "pump_and_dump.png",
    },
        {
      title: "LIQUIDITY•PROVIDER",
      image: "liquidity_provider.png",
    },
    {
      title: "MADE•IN•CHINA",
      image: "made_in_china.png",
    },
    {
      title: "RUNEBRELLACORP",
      image: "runebrellacorp.png",
    },
    {
      title: "GALACTIC•FEDERATION",
      image: "galactic_federation.png",
    },
        {
      title: "BITCOIN•CARTEL",
      image: "bitcoin_cartel.png",
    },
    {
      title: "MASTER•CHEFS",
      image: "master_chefs.png",
    },
    {
      title: "PROPAGANDA•MACHINE",
      image: "propaganda_machine.png",
    },
    {
      title: "IM•SO•HIGH•TECH",
      image: "im_so_hightech.png",
    },
    {
      title: "WHO•DO•YOU•WORK•FOR",
      image: "who_do_you_work_for.png",
    },
    {
      title: "DONT•SLEEP•ON•RUNES",
      image: "dont_sleep_on_runes.png",
    },
    {
      title: "DUDE•WHERES•MY•PRIVATE•KEYS",
      image: "dude_wheres_my_private_keys.png",
    },
    {
      title: "GANG•GANG•GANG",
      image: "gang_gang_gang.png",
    },
    {
      title: "MOON•LANDING",
      image: "moon_landing.png",
    },
    {
      title: "MY•AI•NANO•TECH",
      image: "my_ai_nano_tech.png",
    },
    {
      title: "THE•ORANGE•PILLS",
      image: "the_orange_pills.png",
    },
    {
      title: "BOARD•OF•DIRECTORS",
      image: "board_of_directors.png",
    },
    {
      title: "CHAIRMAN•OF•THE•BOARD",
      image: "chairmain_of_the_board.png",
    },
    {
      title: "SPIRIT•OF•BITCOIN",
      image: "spirit_of_bitcoin.png",
    },
    {
      title: "UNVACCINATED•SPERM",
      image: "unvaxcinated.png",
    },
    {
      title: "CYBER•WAR•GOD",
      image: "cyber_war_god.png",
    },
    {
      title: "MISSION•CONTROL",
      image: "missioncontrol.png",
    },
      {
      title: "DATA•DEPENDENT•ROADMAP",
      image: "data_dependdent.png",
    },
    {
      title: "SECRET•SOCIETY",
      image: "secret_socitey .png",
    },
    {
      title: "ETCHERS•ANONYMOUS",
      image: "etchers_anonymous.png",
    }


];

  return (
    <Box p={4}>
      {/* Title */}
      <Text
        fontSize="24px"
        fontWeight="bold"
        color="#ff9c00"
        textAlign="center"
        mb={8}
      >
        ORANGE•PILLS SEASON #1
      </Text>

      {/* Grid of cards */}
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={6}
      >
        {cards.map((card, index) => (
          <StatusCard
            key={index}
            title={card.title}
            image={card.image}
            index={index}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default Screen3;
