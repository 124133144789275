import React from 'react';
import {
  Box,
  Text,
  VStack,
} from '@chakra-ui/react';

const ComingSoon = ({title}) => {
  return (
    <Box position="relative">
      {/* Title */}
      <Text
        fontSize="24px"
        fontWeight="bold"
        color="#ff9c00"
        textAlign="center"
        mb={8}
      >
        {title}
      </Text>

      {/* Coming Soon Message Container */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minH="50vh"
        border="1px solid #0088ff"
        mx={4}
      >
        <VStack spacing={4}>
          {[...Array(6)].map((_, i) => (
            <Text
              key={i}
              color="#ff9c00"
              fontSize="48px"
              fontWeight="bold"
              textAlign="center"
            >
              COMING SOON !
            </Text>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default ComingSoon;