import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

const stats = `PlayerStats: 
01. 0qw57l86 is Level 3.
02. plw5gkqg is Level 2.
03. wqlfacc0 is Level 2.
04. uqmku0j0 is Level 2.
05. nscddnlc is Level 2.
06. wqt2t760 is Level 2.
07. vqg5t3q6 is Level 2.
08. 0qqa2vya is Level 2.
09. 5qzznsm6 is Level 2.
10. dstllky8 is Level 2.
11. ys26zj7d is Level 2.
12. yq0awdhh is Level 2.
13. 4ap6p29l is Level 2.
14. jsxt8w6t is Level 2.
15. as0l7fd4 is Level 2.
16. eq57tvvv is Level 2.
17. 6m2dyvma is Level 2.
18. wqh2znwr is Level 2.
19. cs83auxl is Level 2.
20. qqhuyea2 is Level 2.
21. lqdqxqrf is Level 2.
22. qsxel8ap is Level 2.
23. mqtegutx is Level 2.
24. xq4qvp35 is Level 2.
25. ws4l8t43 is Level 2.
26. 4qvhur3x is Level 1.
27. 5qyyvm2l is Level 1.
28. gs8m7ywr is Level 1.`;

const PlayerStats = () => {
  return (
    <Box color="orange.500" fontFamily="monospace" fontSize="14px" p={4}>
      {/* Header */}
      <Flex justify="center" mb={4}>
        <Text>
         Players will race to level 100, to obtain a Secret Bitcoin Surprise and Superpowers... 
        </Text>
      </Flex>

      {/* Stats Paragraph */}
      <Text whiteSpace="pre-line">{stats}</Text>
    </Box>
  );
};

export default PlayerStats;
