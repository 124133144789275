import React from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Link,
  Divider,
} from '@chakra-ui/react';

const Screen1 = () => {
  const getCurrentMonth = () => {
    return new Date().toLocaleString('default', { month: 'long' });
  };

  const currentMonth = getCurrentMonth();

  return (
    <Stack spacing={6}>
      <Box textAlign="center">
        <Heading
          as="h1"
          size="xl"
          color="orange.400"
          fontWeight="bold"
        >
          ORANGE•PILLS SEASON #1
        </Heading>
      </Box>

      <section className="section">
        <Text fontWeight="semibold">
          <strong>How to Play:</strong>
        </Text>
        <Text color="white">
          Sacrifice the required BITCOIN RUNE by depositing into the addresses below for the desired result.
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>Subscription Details:</strong>
        </Text>
        <Text color="white">
          Depositing entitles you to one random low-cap RUNE mint per subscription every day throughout {currentMonth}. This offer is <strong>retroactive</strong>, meaning you will receive mints for the days already passed in the month!
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text color="white">
          You may find a <strong>DAILY•RUNE•SUBSCRIPTION</strong> at:
        </Text>
        <Box ml={4}>
          <Link href="https://unisat.io/runes/market?tick=DAILY%E2%80%A2RUNE%E2%80%A2SUBSCRIPTION" color="blue.400" isExternal>
            Unisat Link
          </Link>
          <br />
          <Link href="https://magiceden.us/runes/DAILY%E2%80%A2RUNE%E2%80%A2SUBSCRIPTION" color="blue.400" isExternal>
            Magic Eden Link
          </Link>
        </Box>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section warning-section">
        <Text fontWeight="semibold" color="white">
          <strong>Warning:</strong> Only deposit the <strong>DAILY•RUNE•SUBSCRIPTION</strong>
        </Text>
        <Text color="white">
          <strong>Deposit Address:</strong> <br />
          <Text as="span" wordBreak="break-word">
            bc1pps56rs0t7954v7rwqmjw5zt7s7c50cyt7temvjrckm8sjxwsd3hqferv2u
          </Text>
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>Upgrade Subscription Modules:</strong>
        </Text>
        <Text color="white">
          HODL the <strong>ORANGE•PILLS</strong> Bitcoin Rune for multiplier effects on your <strong>DAILY•RUNE•SUBSCRIPTION</strong> airdrop mints. 
          <br />  
          You may also sacrifice them to own part of the fabric of the <strong>Bitcoin Matrix</strong>. 
          <br />
          <br />
          Enroll in any module to become a founder in our <strong>RUNE•LAUNCH•PAD</strong> support our exciting initiatives for exciting rewards and shares in the venture capital offerings used for voting and controling the meme portfolio.
        </Text>
      </section>
      <section className="section">
        <Text color="white">
          You may find some <strong>ORANGE•PILLS</strong> at:
        </Text>
        <Box ml={4}>
          <Link href="https://magiceden.io/runes/ORANGE%E2%80%A2PILLS" color="blue.400" isExternal>
            Unisat Link
          </Link>
          <br />
          <Link href="https://magiceden.io/runes/ORANGE%E2%80%A2PILLS" color="blue.400" isExternal>
            Magic Eden Link
          </Link>
        </Box>
      </section>
      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>Meme•Lord•Venture•Capital</strong> (Comic Relief)
        </Text>
        <Text color="white">
          <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
        </Text>
        <Text color="white">
          <strong>Deposit Address:</strong> <br />
          <Text as="span" wordBreak="break-word">
            bc1py4643jq7kvat0hh0vz6gsgh04a9mxxm8x85nlgmjmd9mc8xtc3ps4ddm6e
          </Text>
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>Schizoid•Venture•Capital</strong> (Health and Sci-Fi)
        </Text>
        <Text color="white">
          <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
        </Text>
        <Text color="white">
          <strong>Deposit Address:</strong> <br />
          <Text as="span" wordBreak="break-word">
            bc1py3rnghje2kr7wu8lmv2mw3sd73d5lmdhppsww6qatc3jkv54794slwg069
          </Text>
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>Cyber•Security•VC</strong> (Security Tools)
        </Text>
        <Text color="white">
          <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
        </Text>
        <Text color="white">
          <strong>Deposit Address:</strong> <br />
          <Text as="span" wordBreak="break-word">
            bc1pa2krmc9gnfhkxr0dk59s88hdq5770xntzytt29e7x84hrs7g4hkq2kzcpc
          </Text>
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>Sacrifice•Venture•Capital</strong> (Esoteric)
        </Text>
        <Text color="white">
          <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
        </Text>
        <Text color="white">
          <strong>Deposit Address:</strong> <br />
          <Text as="span" wordBreak="break-word">
            bc1prw3a6w5shd6xqzmwm2mdqwn0lq3yu8t4ekt8rnfxs629fny4723snvwzlj
          </Text>
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold">
          <strong>RUNEBRELLACORP</strong> (Business & Experiments)
        </Text>
        <Text color="white">
          <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
        </Text>
        <Text color="white">
          <strong>Deposit Address:</strong> <br />
          <Text as="span" wordBreak="break-word">
            bc1pu0anz2x72e0u2pej2je2xtrgarn37hkejrunv9mu9fh56w3hwsjq7twtyx
          </Text>
        </Text>
      </section>

      <Divider borderColor="orange.400" />

      <section className="section">
        <Text fontWeight="semibold" color="white">
          <strong>Colab??????</strong> (Community Growth)
        </Text>
        <Text color="white">
          If you want to distribute your BITCOIN RUNE to our community, contact us at:
          <br />
          <br />
          <Link href="mailto:ORANGEPILLSRUNE@gmail.com" color="blue.400">
            ORANGEPILLSRUNE@gmail.com
          </Link>
          <br /> <br />
          <Link href="https://x.com/ORANGEPILLZZ" color="blue.400">
            @ORANGEPILLZZ
          </Link>
        </Text>
        <Text color="white">
          <br />
          After approval and vetting, we will organize the distribution.
        </Text>
      </section>
    </Stack>
  );
};

export default Screen1;
