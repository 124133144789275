import React, { useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Link,
} from '@chakra-ui/react';
import SubNav from '../components/SubNav'

const Screen5 = () => {
  const [activeItem, setActiveItem] = useState('POINTS BREAKDOWN');

  // Regular COMING SOON text
  const ComingSoonText = ({ index }) => (
    <Text
      color="#ff9c00"
      fontSize="48px"
      fontWeight="bold"
      textAlign="center"
      whiteSpace="pre"
    >
      {index === 4 ? (
        <>
          COMING S
          <Link
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            target="_blank"
            rel="noopener noreferrer"
            display="inline"
            textDecoration="none"
            color="#ff9c00"
            _hover={{ color: '#ff9c00' }}
          >
            O
          </Link>
          ON !
        </>
      ) : (
        'COMING SOON !'
      )}
    </Text>
  );

  return (
    <VStack spacing={6} align="stretch">
      {/* <SubNav activeItem={activeItem} setActiveItem={setActiveItem} /> */}
      
      {/* Coming Soon Message Container */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minH="50vh"
        py={10}
        border="1px solid #0088ff"
        mx={4}
      >
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <ComingSoonText key={index} index={index} />
        ))}
      </Box>

      <style jsx global>{`
        @keyframes fade {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.7; }
        }
      `}</style>
    </VStack>
  );
};

export default Screen5;