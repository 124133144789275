import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

const sacrifices = `Sacrifices:
Secret Sacrifices = $OP 2,133,200

ws4l8t43					10k
gs8m7ywr					400k
0qw57l86					200k
5qyyvm2l					1,203,200
4qvhur3x					320,000

Meme•Lord•Venture•Capital = $OP 1,275,119

0qw57l86					5k
ws4l8t43					10k
plw5gkqg					20k
wqlfacc0					50
fq5vg7ne					12k
ls5kx9zn					1MM
jqx56enc					20k
jsxt8w6t					30k
wqh2znwr					25k
qqhuyea2					1k
mqtegutx					10k
vqg5t3q6					100k
yqazcmh6					42,069

Schizoid•Venture•Capital $OP 98,060

ws4l8t43					10k
yqazcmh6					10
plw5gkqg					20k
wqlfacc0					50
jsxt8w6t					12k
jqx56enc					20k
wqh2znwr					25k
qqhuyea2					1k
mqtegutx					10k

Cyber•Security•VC $OP 160,420

ws4l8t43					10k
0qw57l86					5k
plw5gkqg					20k
jqx56enc					20k
wqh2znwr					25k
qqhuyea2					1k
mqtegutx					10k
kq2kzcpc					69,420

Sacrifice•Venture•Capital $OP 85,100

ws4l8t43					10k
plw5gkqg					20k
wqlfacc0					100
jqx56enc					20k
3snvwzlj					25k
mqtegutx					10k

RUNEBRELLACORP $OP 213,506

0qw57l86					5k
ws4l8t43					10k
wqlfacc0					50
plw5gkqg					20k
jqx56enc					20k
mqtegutx					10k
wqh2znwr					25k
jq7twtyx					123,456`;

const Sacrifices = () => {
  return (
    <Box color="orange.500" fontFamily="monospace" fontSize="14px" p={4}>
      {/* Header */}
      <Flex justify="center" mb={4}>
        <Text>
    Those loyal to the cult will be rewarded 1000x | Secret Sacrifices 3x points | RUNEBRELLACORP $OP 213,506 | Sacrifice•Venture•Capital $OP 85,100 | Cyber•Security•VC $OP 160,420 | Schizoid•Venture•Capital $OP 98,060 | Meme•Lord•Venture•Capital = $OP 1,275,119 | $OP 3,965,405 TOTAL ORANGE PILLS SACRIFICED |
        </Text>
      </Flex>

      {/* Sacrifices Paragraph */}
      <Text whiteSpace="pre-line">{sacrifices}</Text>
    </Box>
  );
};

export default Sacrifices;
