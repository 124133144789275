import React from 'react';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  IconButton,
  useDisclosure
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

const MobileNavigation = ({ currentScreen, setCurrentScreen, setTitle }) => {
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose
  } = useDisclosure();

  const mainItems = [
    "HOW TO PLAY",
    "SPEAK TO THE ORACLE",
    "DATA•DEPENDENT•ROADMAP",
    "WAIT LIST"
  ];

  const subMenuItems = [
    "POINTS BREAKDOWN",
    "PLAYER STATS",
    "SACRIFICES",
    "RUNE•LAUNCH•PAD",
    "HODL GODS",
    "SCAVENGER•HUNT"
  ];

  const isComingSoon = (x) => [
    "RUNE•LAUNCH•PAD",
    "HODL GODS",
    "SCAVENGER•HUNT"
  ].includes(x);

  return (
    <Box position="relative" zIndex={999}>
      {/* Mobile Navigation Bar */}
      <Box
        bg="#ff9c00"
        width="100%"
        height="32px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
      >
        <IconButton
          icon={<HamburgerIcon />}
          onClick={onMenuOpen}
          variant="ghost"
          color="black"
          aria-label="Open Navigation Menu"
        />
        <Text
          color="black"
          fontSize="13px"
          fontWeight="bold"
          fontFamily="monospace"
        >
          MENU
        </Text>
      </Box>

      {/* Mobile Drawer Menu */}
      <Drawer
        isOpen={isMenuOpen}
        placement="left"
        onClose={onMenuClose}
      >
        <DrawerOverlay />
        <DrawerContent bg="#ff9c00">
          <DrawerCloseButton />
          <DrawerHeader
            textAlign="center"
            fontFamily="monospace"
            fontWeight="bold"
          >
            NAVIGATION
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="center">
              {mainItems.map((item, index) => (
                <Text
                  key={item}
                  color="black"
                  cursor="pointer"
                  onClick={() => {
                    setCurrentScreen(index + 1);
                    onMenuClose();
                  }}
                  fontSize="13px"
                  fontWeight="bold"
                  letterSpacing="0.5px"
                  fontFamily="monospace"
                  _hover={{ opacity: 0.8 }}
                >
                  {item}
                </Text>
              ))}
              <Menu>
                <MenuButton
                  as={Text}
                  color="black"
                  fontSize="13px"
                  fontWeight="bold"
                  letterSpacing="0.5px"
                  fontFamily="monospace"
                  cursor="pointer"
                  _hover={{ opacity: 0.8 }}
                >
                  LEADER BOARD
                </MenuButton>
                <MenuList
                  bg="#ff9c00"
                  border="none"
                  borderRadius="0"
                  padding="4"
                  width="100%"
                >
                  <VStack spacing={4} align="center">
                    {subMenuItems.map((item, index) => (
                      <Text
                        key={item}
                        color="black"
                        cursor="pointer"
                        fontSize="13px"
                        fontWeight="bold"
                        letterSpacing="0.5px"
                        fontFamily="monospace"
                        onClick={() => {
                          if (isComingSoon(item)) {
                            setTitle(item);
                            setCurrentScreen(99);
                            onMenuClose();
                          }
                          else
                          {
                            setCurrentScreen(index + 8)
                            setTitle(item)
                          }
                        }}
                        _hover={{ opacity: 0.8 }}
                      >
                        {item}
                      </Text>
                    ))}
                  </VStack>
                </MenuList>
              </Menu>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileNavigation;