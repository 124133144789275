import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const Navigation = (props) => {
  const isDesktop = useBreakpointValue({
    base: false,  // 0-480px
    sm: false,    // 480-768px
    md: true,     // 768px and above
  });

  return isDesktop ? (
    <DesktopNavigation {...props} />
  ) : (
    <MobileNavigation {...props} />
  );
};

export default Navigation;