import React from 'react';
import { 
  Box, 
  Text,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure
} from '@chakra-ui/react';

const DesktopNavigation = ({ currentScreen, setCurrentScreen, setTitle, title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mainItems = [
    "HOW TO PLAY",
    "SPEAK TO THE ORACLE",
    "DATA•DEPENDENT•ROADMAP",
    "WAIT LIST"
  ];

  const subMenuItems = [
    "POINTS BREAKDOWN",
    "PLAYER STATS",
    "SACRIFICES",
    "RUNE•LAUNCH•PAD",//c
    "HODL GODS",//c
    "SCAVENGER•HUNT"//c
  ];

  const isComingSoon = (x) => [
    "RUNE•LAUNCH•PAD",//c
    "HODL GODS",//c
    "SCAVENGER•HUNT"//c
  ].includes(x)

  //RUNE•LAUNCH•PAD, HODL•GODS, SCAVENGER•HUNT should be coming soon

  return (
    <Box position="relative" zIndex={999}>
      <Box
        bg="#ff9c00"
        width="100%"
        height="32px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box 
          display="flex" 
          alignItems="center"
          gap="8px"
        >
          {mainItems.map((item, index) => (
            <Box
              key={item}
              display="inline-flex"
              alignItems="center"
            >
              <Text
                color="black"
                cursor="pointer"
                onClick={() => setCurrentScreen(index + 1)}
                fontSize="13px"
                fontWeight="bold"
                letterSpacing="0.5px"
                fontFamily="monospace"
                _hover={{ opacity: 0.8 }}
              >
                {item}
              </Text>
              <Text color="black" ml="8px" fontWeight="bold">|</Text>
            </Box>
          ))}
          
          <Menu isOpen={isOpen} placement="bottom-end">
            <MenuButton
              as={Text}
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              cursor="pointer"
              color="black"
              fontSize="13px"
              fontWeight="bold"
              letterSpacing="0.5px"
              fontFamily="monospace"
              height="32px"
              padding={0}
              display="flex"
              alignItems="center"
              _hover={{ opacity: 0.8 }}
              _active={{ bg: 'transparent' }}
              _focus={{ boxShadow: 'none', outline: 'none' }}
              border="none"
              bg="transparent"
            >
              LEADER BOARD
            </MenuButton>
            <MenuList 
              onMouseEnter={onOpen} 
              onMouseLeave={onClose}
              bg="#ff9c00"
              border="none"
              borderRadius="0"
              padding="0"
              position="absolute"
              right="-10"
              top={-2}
              height="32px"
              marginBlock="0"
              marginRight="-4px"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              boxShadow="none"
              minW="auto"
            >
              <Box display="flex" alignItems="center" gap="8px" px={4}>
                {subMenuItems.map((item, index) => (
                  <Box key={item} display="inline-flex" alignItems="center">
                    <Text
                      color="black"
                      cursor="pointer"
                      fontSize="13px"
                      fontWeight="bold"
                      letterSpacing="0.5px"
                      fontFamily="monospace"
                      onClick={()=>{
                        if(isComingSoon(item))
                         {
                           setTitle(item)
                           setCurrentScreen(99)
                         }
                         else
                         {
                           setCurrentScreen(index + 8)
                           setTitle(item)
                         }
                      }}
                      _hover={{ opacity: 0.8 }}
                      whiteSpace="nowrap"
                    >
                      {item}
                    </Text>
                    {index !== subMenuItems.length - 1 && (
                      <Text color="black" ml="8px" fontWeight="bold">|</Text>
                    )}
                  </Box>
                ))}
              </Box>
            </MenuList>
          </Menu>
          <Text color="black" ml="8px" fontWeight="bold">|</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopNavigation;