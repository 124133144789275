import React, { useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  useToast,
} from '@chakra-ui/react';

const WaitlistForm = () => {
  const toast = useToast();
  const [form, setForm] = useState({
    email: '',
    taprootAddress: '',
    telegram: '',
    discord: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    // Email validation
    if (!form.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Email is invalid';
    }

    // Taproot address validation
    if (!form.taprootAddress) {
      newErrors.taprootAddress = 'Taproot address is required';
    } else if (!/^bc1p[a-zA-Z0-9]{58,}$/.test(form.taprootAddress)) {
      newErrors.taprootAddress = 'Invalid Taproot address format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
        const formData = new FormData();
        
        // Replace these entry IDs with the actual ones from your Google Form
        formData.append('entry.1171561279', form.email);         // Email field
        formData.append('entry.1251209554', form.taprootAddress); // Taproot Address field
        formData.append('entry.171718207', form.telegram);     // Telegram field
        formData.append('entry.1766813588', form.discord);      // Discord field

        // Your Google Form submission URL
        const FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSeMKU2EXrxFlXTH4kWGz4GtOVwXUGzhz48CVTETN8sJldVnxA/formResponse';
        
        await fetch(FORM_URL, {
            method: 'POST',
            mode: 'no-cors', // This is important
            body: formData
        });

        // Clear form
        setForm({
            email: '',
            taprootAddress: '',
            telegram: '',
            discord: '',
        });

        toast({
            title: 'Success!',
            description: 'You have been added to the waitlist.',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        toast({
            title: 'Error',
            description: 'Failed to join waitlist. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsSubmitting(false);
    }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <VStack spacing={6} align="stretch">
      {/* Title */}
      <Text
        fontSize="24px"
        fontWeight="bold"
        color="#ff9c00"
        textAlign="center"
      >
        ORANGE•PILLS SEASON #1
      </Text>

      {/* Form */}
      <Box
        as="form"
        onSubmit={handleSubmit}
        bg="black"
        p={6}
        borderRadius="sm"
        border="1px solid #ff9c00"
      >
        <VStack spacing={4}>
          <FormControl isRequired isInvalid={errors.email}>
            <FormLabel color="#ff9c00">Email Address</FormLabel>
            <Input
              name="email"
              value={form.email}
              onChange={handleChange}
              bg="#333"
              color="white"
              border="1px solid #ff9c00"
              _hover={{ borderColor: '#ffb84d' }}
              _focus={{ borderColor: '#ffb84d', boxShadow: 'none' }}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={errors.taprootAddress}>
            <FormLabel color="#ff9c00">Taproot Address</FormLabel>
            <Input
              name="taprootAddress"
              value={form.taprootAddress}
              onChange={handleChange}
              bg="#333"
              color="white"
              border="1px solid #ff9c00"
              _hover={{ borderColor: '#ffb84d' }}
              _focus={{ borderColor: '#ffb84d', boxShadow: 'none' }}
            />
            <FormErrorMessage>{errors.taprootAddress}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel color="#ff9c00">Telegram Handle (Optional)</FormLabel>
            <Input
              name="telegram"
              value={form.telegram}
              onChange={handleChange}
              bg="#333"
              color="white"
              border="1px solid #ff9c00"
              _hover={{ borderColor: '#ffb84d' }}
              _focus={{ borderColor: '#ffb84d', boxShadow: 'none' }}
            />
          </FormControl>

          <FormControl>
            <FormLabel color="#ff9c00">Discord Handle (Optional)</FormLabel>
            <Input
              name="discord"
              value={form.discord}
              onChange={handleChange}
              bg="#333"
              color="white"
              border="1px solid #ff9c00"
              _hover={{ borderColor: '#ffb84d' }}
              _focus={{ borderColor: '#ffb84d', boxShadow: 'none' }}
            />
          </FormControl>

          <Button
            type="submit"
            bg="#ff9c00"
            color="black"
            width="100%"
            _hover={{ bg: '#ffb84d' }}
            isLoading={isSubmitting}
            loadingText="Submitting"
          >
            Submit
          </Button>
        </VStack>
      </Box>
    </VStack>
  );
};

export default WaitlistForm;
