import React, { useState } from 'react';
import {
  Box,
} from '@chakra-ui/react';
import Navigation from './Navigation';
import Screen1 from '../screens/Screen1'
import './Overlay.css';
import Screen2 from '../screens/Screen2';
import Screen3 from '../screens/Screen3';
import Screen4 from '../screens/Screen4';
import Screen5 from '../screens/Screen5';
import Screen6 from '../screens/Screen6';
import ComingSoon from './ComingSoon';
import PlayerAccolades from '../screens/PlayerAccolades';
import PointsBreakdown from '../screens/PointsBreakdown';
import PlayerStats from '../screens/PlayerStats';
import Sacrifices from '../screens/Scarifices';

const Overlay = () => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [title, setTitle] = useState('');

  return (
    <Box 
      width={['95%', '95%', '1000px']} // Responsive width
      mx="auto"
      mt="2vh"
      position="relative"
      px={[2, 4, 6]} // Responsive padding
    >
      <Navigation 
        currentScreen={currentScreen} 
        setCurrentScreen={setCurrentScreen} 
        setTitle={setTitle}
        title={title}
      />

      {/* Main Content Area */}
      <Box
        bg="black"
        position="relative"
        height="auto"
        minH={['90vh', '85vh']} // Responsive height
        borderRight="4px solid #ff9c00"
      >
        {/* Content Container */}
        <Box
          position="relative"
          zIndex={1}
          p={['12px', '16px', '24px']} // Responsive padding
          color="white"
          overflowY="auto"
          maxH={['90vh', '85vh']} // Responsive max height
        >
          {currentScreen === 1 && <Screen1 />}
          {currentScreen === 2 && <Screen2 />}
          {currentScreen === 3 && <Screen3 />}
          {currentScreen === 4 && <Screen4 />}
          {currentScreen === 5 && <Screen5 />}
          {currentScreen === 6 && <Screen6 />}
          {currentScreen === 7 && <PlayerAccolades />}
          {currentScreen === 8 && <PointsBreakdown title={title} />}
          {currentScreen === 9 && <PlayerStats title={title} />}
          {currentScreen === 10 && <Sacrifices title={title} />}
          {currentScreen === 99 && <ComingSoon title={title} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Overlay;
